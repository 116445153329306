import $ from 'jquery';

(function($, llprod) {
	if (typeof(window.llprod) === 'undefined') {
		window.llprod = {};
	}

	var __getitem__ = function __getitem__(name)
	{
		return this[name];
	};

	if (typeof(llprod.globals) === 'undefined' && typeof(ul4) !== 'undefined' && typeof(AjaxURLManager) !== 'undefined'){
		if (!AjaxURLManager.getUrlWithKey('getUL4TemplatesAction.do')){
			AjaxURLManager.registerKey('getUL4TemplatesAction.do', null, null, false, '/xist4c/admin/');
		}
		if (!AjaxURLManager.getUrlWithKey('getUL4TextsAction.do')){
			AjaxURLManager.registerKey('getUL4TextsAction.do', null, null, false, '/xist4c/admin/');
		}
		llprod.globals = {
			"templates": {
				_cache: {},
				__preloadcache__: function(data) {
					var url = AjaxURLManager.getUrlWithKey('getUL4TemplatesAction.do'), i,
					realData = [], self = this;
					for (i = 0; i < data.length; i++) {
						realData.push({"name": 'templateIdentifier', "value": data[i]});
					}
					if (realData.length){
						return $.ajax(url, {
							"async": false,
							"type": 'post',
							"data": realData,
							"success": function(data, textStatus, xhr) {
								var k;
								data = ul4._map2object(ul4.loads(data));
								for (k in data) {
									self._cache[k] = data[k];
								}
							}
						});
					}
				},
				__getattr__: function(name) {
					var tpl = this._cache[name], url, self = this;
					if (typeof(tpl) === 'undefined'){
						url = AjaxURLManager.getUrlWithKey('getUL4TemplatesAction.do', {
							"templateIdentifier": name
						});
						$.ajax(url, {
							"async": false,
							"type": 'post',
							"success": function(data, textStatus, xhr) {
								data = ul4._map2object(ul4.loads(data));
								var k;
								for (k in data) {
									self._cache[k] = data[k];
								}
							}
						});
						tpl = this._cache[name];
					}
					return tpl;
				},
				__getitem__: function(name) {
					return this.__getattr__(name);
				},
				[ul4.symbols.getitem]: function(name) {
					return this.__getitem__(name);
				},
				[ul4.symbols.getattr]: function(name) {
					return this.__getattr__(name);
				}
			},
			"lang": $.lang(),
			"texts": {
				_cache: {},
				__preloadcache__: function(data) {
					var url = AjaxURLManager.getUrlWithKey('getUL4TextsAction.do'), i,
					realData = [], self = this;
					for (i = 0; i < data.length; i++) {
						realData.push({"name": 'textIdentifier', "value": data[i]});
					}
					if (realData.length){
						return $.ajax(url, {
							"async": false,
							"type": 'post',
							"data": realData,
							"success": function(data, textStatus, xhr) {
								var k;
								for (k in data) {
									var value = data[k];
									if (value !== null)
									{
										value.__getitem__ = value[ul4.symbols.getattr]
										                  = value[ul4.symbols.getitem]
										                  = __getitem__;
									}
									self._cache[k] = value;
								}
							}
						});
					}
				},
				__getattr__: function(name) {
					var text = this._cache[name] ? this._cache[name] : undefined, url, self = this;
					if (typeof(text) === 'undefined'){
						url = AjaxURLManager.getUrlWithKey('getUL4TextsAction.do', {
							"textIdentifier": name
						});
						$.ajax(url, {
							"async": false,
							"type": 'post',
							"success": function(data, textStatus, xhr) {
								var k;
								for (k in data) {
									var value = data[k];
									if (value !== null)
									{
										value.__getitem__ = value[ul4.symbols.getattr]
										                  = value[ul4.symbols.getitem]
										                  = __getitem__;
									}
									self._cache[k] = value;
								}
							}
						});
						text = this._cache[name];
					}
					return text;
				},
				__gettext__: function(name) {
					return this.__getattr__(name)[llprod.globals.lang];
				},
				__getitem__: function(name) {
					return this.__getattr__(name);
				},
				[ul4.symbols.getitem]: function(name) {
					return this.__getitem__(name);
				},
				[ul4.symbols.getattr]: function(name) {
					return this.__getattr__(name);
				}
			}
		};
	}
})(ll.$, window.llprod);